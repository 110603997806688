import City from '@/models/City'
import Developer from '@/models/Developer'

export const state = () => ({
    loading: false,
    cities: [],
    developers: []
})

export const getters = {
    loading: state => state.loading,
    cities: state => state.cities,
    developers: state => state.developers
}

export const mutations = {
    FETCH_LOADING (state, loading) {
        state.loading = loading
    },
    FETCH_CITIES (state, cities) {
        state.cities = cities
    },
    FETCH_DEVELOPERS (state, developers) {
        state.developers = developers
    }
}

export const actions = {
    async fetchCities ({ commit }) {
        commit('FETCH_LOADING', true)

        try {
            commit('FETCH_CITIES', await City.select('name_hy', 'name_en', 'name_ru', 'slug').get())
        } catch (e) {
            console.log(e)
        } finally {
            commit('FETCH_LOADING', false)
        }
    },
    async fetchDevelopers ({ commit }) {
        commit('FETCH_LOADING', true)

        try {
            commit('FETCH_DEVELOPERS', await Developer.select('name_hy', 'name_en', 'name_ru', 'slug').get())
        } catch (e) {
            console.log(e)
        } finally {
            commit('FETCH_LOADING', false)
        }
    },
    // resets
    resetCities ({ commit }) {
        commit('FETCH_CITIES', [])
    },
    resetDevelopers ({ commit }) {
        commit('FETCH_DEVELOPERS', [])
    }
}
