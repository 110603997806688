export default {
    home_title: 'Новостройки Армении. Вся информация о строящихся домах по всей стране.',
    home_description: 'Вся информация о любых новостройках Армении. На нашем сайте отзывы, проекты, застройщики и иные подробности о строящемся жилье.',
    projects_title: 'Единая база проверенных новостроек Армении.',
    projects_description: 'Все новостройки Армении. Расположение на карте, фото, контакты, подробная информация по каждому объекту – все это в проверенной базе новостроек Армении.',
    projects_with_location_title: (ctx) => {
        let title_text = ''
        let check = !ctx.named('city_name') && !ctx.named('developer_name') && !ctx.named('year')
        if (!check) {
            if (ctx.named('city_name')) {
                title_text += `${ctx.named('city_name')} новостройки `
            } else if (!ctx.named('city_name')) {
                title_text += `Новостройки `
            }

            if (ctx.named('developer_name')) {
                title_text += `от застройщика ${ctx.named('developer_name')} `
            } else if (!ctx.named('developer_name') && !ctx.named('year')) {
                title_text += 'от застройщика'
            }

            if (ctx.named('year') === 'ready') {
                title_text += 'сданные.'
            } else if (ctx.named('year')) {
                title_text += `сдача в ${ctx.named('year')} году.`
            } else if (!ctx.named('year')) {
                title_text += '.'
            }
        } else {
            title_text = 'Новостройки от застройщика.'
        }

        return title_text
    },
    projects_with_location_description: (ctx) => {
        let text = 'Купить квартиру '
        let check = !ctx.named('city_name') && !ctx.named('developer_name') && !ctx.named('year')
        if (!check) {
            if (ctx.named('developer_name')) {
                text += `от застройщика ${ctx.named('developer_name')} `
            } else if (!ctx.named('developer_name') && !ctx.named('year')) {
                text += 'от застройщика '
            }

            if (ctx.named('city_name') && ctx.named('year') === 'ready') {
                text += `в городе ${ctx.named('city_name')} в сданной новостройке.`
            } else if (ctx.named('city_name')) {
                text += `в городе ${ctx.named('city_name')} в новостройке`
            } else if (!ctx.named('city_name')) {
                text += `в новостройке`
            }


            if (ctx.named('year') && ctx.named('year') !== 'ready') {
                text += ` сдача в ${ctx.named('year')} год.`
            } else if (!ctx.named('year')) {
                text += '.'
            }
        } else {
            text = 'Купить квартиру в новостройке.'
        }

        return text
    },
    project_with_name_title: (ctx) => `ЖК ${ctx.named('project_name')} – вся подробная информация о жилом комплексе.`,
    project_with_name_description: (ctx) => `ЖК ${ctx.named('project_name')}: Посмотреть отзывы покупателей о проекте ${ctx.named('project_name')}. Посмотреть на карте проект ${ctx.named('project_name')}.  Фото, описание, инфаструктура, предложения банков.`,
    banks_title: 'Банки Армении с ипотекой на покупку квартиры в новостройках.',
    banks_description: 'Купить квартиру в ипотеку в одной из новостроек в Армении с помощью одного из банков.',
    bank_with_name_title: (ctx) => `Банк ${ctx.named('bank_name')} в Армении – отзывы, ипотечные программы.`,
    bank_with_name_description: (ctx) => `Страница банка ${ctx.named('bank_name')} на сайте: информация, контакты и ссылка на официальный сайт банка.`,
    developers_title: 'Строительные компании Армении – база застройщиков со всей страны.',
    developers_description: 'Подробная база застройщиков и строительных компаний Армении. Актуальные новостройки застройщиков, отзывы и другая информация.',
    developer_title: (ctx) => `Строительная компания ${ctx.named('developer_name')} Армении – отзывы покупателей и новостройки застройщика.`,
    developer_description: (ctx) => `Страница застройщика ${ctx.named('developer_name')} на сайте, с контактными данными, ссылкой на официальный сайт, подробным описанием строительной компании и списком новостроек от ${ctx.named('developer_name')}.`,
    blogs_title: 'Новости и статьи о недвижимости в Армении.',
    blogs_description: 'Рассказываем об интересных событиях рынка недвижимости. Все об акциях от застройщиков, аналитика экспертов и многое другое. Все новости рынка.',
    blog_title: (ctx) => `${ctx.named('blog_name')}`,
    blog_description: (ctx) => `"${ctx.named('blog_name')}" – новости и статьи на сайте GetHome.`,
    cities_title: 'Новостройки во всех городах Армении.',
    cities_description: 'Проверенная база строящихся домов в любом городе Армении от застройщика: планировки, отзывы покупателей квартир, посмотреть на карте местоположение и многое другое.',
    city_title: (ctx) => `Новостройки в городе ${ctx.named('city_name')}.`,
    city_description: (ctx) => `Проверенная база застройщиков и жилых комплексов в городе ${ctx.named('city_name')}. Вся информация о строящихся домах в городе ${ctx.named('city_name')}.`,
    about_title: 'О сайте Gethome.am',
    about_description: 'Gethome.am – сайт о новостройках и жилых комплексах в Армении.'
}
