export default {
    blog: "Բլոգ",
    comments: "Մեկնաբանություններ",
    username: "Անուն",
    comment_text: "Տեքստ",
    register: 'Գրանցվեք',
    or: 'կամ',
    login: 'մուտք գործեք',
    send: "Ուղարկել"
};
