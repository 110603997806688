export default {
    home_title: 'New buildings in Armenia. All information about houses under construction throughout the country.',
    home_description: 'All information about any new buildings in Armenia. On our website, reviews, projects, developers, and other details about housing under construction.',
    projects_title: 'Unified database of verified new buildings in Armenia.',
    projects_description: 'All new buildings in Armenia. Location on the map, photos, contacts, detailed information on each object - all this is in the verified database of new buildings in Armenia.',
    projects_with_location_title: (ctx) => {
        let title_text = ''
        let check = !ctx.named('city_name') && !ctx.named('developer_name') && !ctx.named('year')
        if (!check) {
            if (ctx.named('city_name')) {
                title_text += `${ctx.named('city_name')}, new buildings `
            } else if (!ctx.named('city_name')) {
                title_text += `New buildings `
            }

            if (ctx.named('developer_name')) {
                title_text += `from the developer ${ctx.named('developer_name')}`
            } else if (!ctx.named('developer_name') && !ctx.named('year')) {
                title_text += 'from the developer'
            }

            if (ctx.named('year') === 'ready') {
                title_text += ' delivered.'
            } else if (!ctx.named('developer_name') && ctx.named('year')) {
                title_text += ` are handed in ${ctx.named('year')}.`
            } else if (ctx.named('year')) {
                title_text += ` delivery in ${ctx.named('year')}.`
            } else if (!ctx.named('year')) {
                title_text += '.'
            }
        } else {
            title_text = 'New buildings from the developer.'
        }

        return title_text
    },
    projects_with_location_description: (ctx) => {
        let text = 'Buy an apartment '
        let check = !ctx.named('city_name') && !ctx.named('developer_name') && !ctx.named('year')
        if (!check) {
            if (ctx.named('developer_name')) {
                text += `from the developer ${ctx.named('developer_name')} `
            }

            if (ctx.named('city_name') && ctx.named('year') === 'ready') {
                text += `in the city ${ctx.named('city_name')} in a newly built building.`
            } else if (ctx.named('city_name')) {
                text += ` in the city ${ctx.named('city_name')} `
            }


            if (ctx.named('year')) {
                text += `in a new building ${ctx.named('year')}.`
            } else if (!ctx.named('year')) {
                text += `in a new building.`
            }
        } else {
            text = 'Buy an apartment in a new building.'
        }

        return text
    },
    project_with_name_title: (ctx) => `LCD ${ctx.named('project_name')} - all the detailed information about the residential complex.`,
    project_with_name_description: (ctx) => `LCD ${ctx.named('project_name')}: View customer reviews about the project ${ctx.named('project_name')}. View project ${ctx.named('project_name')} on the map. Photo, description, infrastructure, offers of banks.`,
    banks_title: 'Banks of Armenia with a mortgage for the purchase of an apartment in new buildings.',
    banks_description: 'Buy an apartment with a mortgage in one of the new buildings in Armenia with the help of one of the banks.',
    bank_with_name_title: (ctx) => `Bank ${ctx.named('bank_name')} in Armenia - reviews, mortgage programs.`,
    bank_with_name_description: (ctx) => `Bank page ${ctx.named('bank_name')} on the website: information, contacts, and a link to the official website of the bank.`,
    developers_title: 'Construction companies in Armenia are based on developers from all over the country.',
    developers_description: 'Detailed database of developers and construction companies in Armenia. Actual new buildings of developers, reviews, and other information.',
    developer_title: (ctx) => `Construction company ${ctx.named('developer_name')} of Armenia - reviews of buyers and new buildings of the developer.`,
    developer_description: (ctx) => `Developer ${ctx.named('developer_name')} page on the site, with contact details, a link to the official website, a detailed description of the construction company and a list of new buildings from ${ctx.named('developer_name')}.`,
    blogs_title: 'News and articles about real estate in Armenia.',
    blogs_description: 'We talk about interesting events in the real estate market. All about promotions from developers, expert analytics and much more. All market news.',
    blog_title: (ctx) => `${ctx.named('blog_name')}`,
    blog_description: (ctx) => `"${ctx.named('blog_name')}" - news and articles on the GetHome website.`,
    cities_title: 'New buildings in all cities of Armenia.',
    cities_description: 'A proven database of houses under construction in any city of Armenia from the developer: layouts, reviews of apartment buyers, view the location on the map and much more.',
    city_title: (ctx) => `New buildings in the city ${ctx.named('city_name')}.`,
    city_description: (ctx) => `A verified base of developers and residential complexes in the city of ${ctx.named('city_name')}. All information about houses under construction in the city ${ctx.named('city_name')}.`,
    about_title: 'About Gethome.am',
    about_description: 'Gethome.am is a site about new buildings and residential complexes in Armenia.'
}
