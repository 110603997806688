export default {
    projects: 'Projects',
    banks: 'Banks',
    developers: 'Developers',
    cities: 'Cities',
    blogs: 'Blog',
    terms_of_use: 'Terms of use',
    about_the_project: 'About the project',
    contact_us: 'Contact us',
}
