import Vue from 'vue'
import VueRouter from 'vue-router'
import publicRoutes from './public.routes'
import adminRoutes from './admin.routes'
import dashboardRoutes from './dashboard.routes'
import VueMeta from 'vue-meta'

Vue.use(VueRouter)
Vue.use(VueMeta)

const routes = [
    ...adminRoutes,
    ...dashboardRoutes,
    ...publicRoutes,
]

const router = new VueRouter({
    mode: 'history',
    base: '/',
    routes,
})

router.afterEach((to) => {
    const nodes = document.querySelectorAll('[hreflang]');
    nodes[0].setAttribute('href', 'https://ru.gethome.am' + to.path)
    nodes[1].setAttribute('href', 'https://gethome.am' + to.path)
    nodes[2].setAttribute('href', 'https://en.gethome.am' + to.path)
})

export default router
