export const state = () => ({
    snackbar: {},
    width_site: 0,
})

export const getters = {
    snackbar: state => state.snackbar,
    getWidth_site: state => state.width_site,
}

export const mutations = {
    hideSnackBar: (state) => {
        state.snackbar = {...state.snackbar, text: '', show: false}
    },
    showSnackBar: (state, snackbar) => {
        state.snackbar = {...snackbar, show: true}
    },
    updateWidth_site(state) {
        state.width_site = window.innerWidth;
    }
}

export const actions = {

}
