export default {
    projects: 'Проекты',
    banks: 'Банки',
    developers: 'Застройщики',
    cities: 'Города',
    blogs: 'Блог',
    terms_of_use: 'Условия эксплуатации',
    about_the_project: 'О проекте',
    contact_us: 'Связаться с нами',
}
