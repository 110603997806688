import Project from '@/models/Project'
import Developer from '@/models/Developer'

export const state = () => ({
    loading: false,
    filter: {
        location: null,
        developer: null,
        completion: null,
    },
    projects: [],
    project: null,
    developers: []
})

export const getters = {
    loading: state => state.loading,
    filter: state => state.filter,
    projects: state => state.projects,
    project: state => state.project,
    developers: state => state.developers
}

export const mutations = {
    FETCH_LOADING (state, loading) {
        state.loading = loading
    },
    FETCH_PROJECTS (state, projects) {
        state.projects = projects
    },
    FETCH_PROJECT (state, project) {
        state.project = project
    },
    FETCH_DEVELOPERS (state, developers) {
        state.developers = developers
    },
    FETCH_FILTER (state, filter) {
        state.filter = filter
    }
}

export const actions = {
    async fetchProjects ({ commit, state }) {
        commit('FETCH_LOADING', true)

        try {
            commit('FETCH_PROJECTS', await Project.when(state.filter, query => query.where(state.filter)).get())
        } catch (e) {
            console.log(e)
        } finally {
            commit('FETCH_LOADING', false)
        }
    },
    async fetchDevelopers ({ commit }) {
        commit('FETCH_LOADING', true)

        try {
            commit('FETCH_DEVELOPERS', await Developer.get())
        } catch (e) {
            console.log(e)
        } finally {
            commit('FETCH_LOADING', false)
        }
    },
    setDevelopers ({ commit }, developers) {
        commit('FETCH_DEVELOPERS', developers)
    },
    setFilter ({ commit }, filter) {
        if (Object.prototype.hasOwnProperty.call(filter, 't')) {
            delete filter.t
        }

        commit('FETCH_FILTER', filter)
    },
    // resets
    resetFilter ({ commit }) {
         commit('FETCH_FILTER', {
             location: null,
             developer: null,
             completion: null,
         })
    },
    resetProjects ({ commit }) {
        commit('FETCH_PROJECTS', [])
    },
    resetDevelopers ({ commit }) {
        commit('FETCH_DEVELOPERS', [])
    }
}
