import Home from "../views/Home";

export default [
    {
        path: '/',
        name: 'home',
        component: Home,
        meta: {}
    },
    {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
        meta: {}
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/Login.vue'),
        meta: {auth: false}
    },
    {
        path: '/sign-up',
        name: 'sign-up',
        component: () => import('../views/SignUp.vue'),
        meta: {auth: false}
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: () => import('../views/ForgotPassword.vue'),
        meta: {auth: false}
    },
    {
        path: '/reset-password',
        name: 'reset-password',
        component: () => import('../views/ResetPassword.vue'),
        meta: {auth: false}
    },
    {
        path: '/403',
        name: '403',
        component: () => import('../views/public/403.vue'),
        meta: {}
    },
    {
        path: '/privacy-policy',
        name: 'privacy-policy',
        component: () => import('../views/public/PrivacyPolicy.vue'),
        meta: {}
    },
    {
        path: '/banks',
        name: 'banks.index',
        component: () => import('../views/public/banks/Index.vue'),
        meta: {}
    },
    {
        path: '/bank/:bank',
        name: 'bank',
        component: () => import('../views/public/banks/Show.vue'),
        meta: {}
    },
    {
        path: '/projects',
        name: 'projects.index',
        component: () => import('../views/public/projects/Index.vue'),
        meta: {}
    },
    {
        path: '/project/:project',
        name: 'projects.show',
        component: () => import('../views/public/projects/Show.vue'),
        meta: {}
    },
    {
        path: '/apartment/:apartment',
        name: 'apartment',
        component: () => import('../views/public/apartments/Show.vue'),
        meta: {}
    },
    {
        path: "*",
        component: () => import('../views/public/404.vue'),
    },
    {
        path: '/developers',
        name: 'developers.index',
        component: () => import('../views/public/developers/Index.vue'),
        meta: {}
    },
    {
        path: '/developer/:developer',
        name: 'developer',
        component: () => import('../views/public/developers/Show.vue'),
        meta: {}
    },
    {
        path: '/cities',
        name: 'cities.index',
        component: () => import('../views/public/cities/Index.vue'),
        meta: {}
    },
    {
        path: '/city/:city',
        name: 'cities.show',
        component: () => import('../views/public/cities/Show.vue'),
        meta: {}
    },
    {
        path: '/blog',
        name: 'blogs.index',
        component: () => import('../views/public/blogs/Index.vue'),
        meta: {}
    },
    {
        path: '/blog/:blog',
        name: 'blogs.show',
        component: () => import('../views/public/blogs/Show.vue'),
        meta: {}
    },
    {
        path: '/404',
        name: '404',
        component: () => import('../views/public/404.vue')
    },
    {
        path: '/*',
        beforeEnter () {
            window.location.href = "/404";
        }
    }
]
