import languages from "./en/languages";
import auth from "./en/auth.js";
import menu from "./en/menu.js";
import headers from "./en/headers.js";
import entities from "./en/entities";
import filterForm from './en/filterForm';
import info_card from "./en/info_card";
import projects_show from "@/locales/en/projects_show";
import banks_filter from "@/locales/en/banks_filter";
import cities_show from "@/locales/en/cities_show";
import blog from "@/locales/en/blog"
import meta from "@/locales/en/meta";

export default {
    languages,
    auth,
    menu,
    headers,
    entities,
    filterForm,
    info_card,
    projects_show,
    banks_filter,
    cities_show,
    blog,
    meta
}
