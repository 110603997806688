export default {
    projects: 'Նախագծեր',
    banks: 'բանկեր',
    developers: 'Կառուցապատողներ',
    cities: 'Քաղաքներ',
    blogs: 'Բլոգ',
    terms_of_use: 'Օգտվելու կանոններ',
    about_the_project: 'Նախագծի մասին',
    contact_us: 'ԿԱՊ ՄԵԶ ՀԵՏ',
}
