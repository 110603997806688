export default {
    home_title: 'Նորակառույցներ Հայաստանում։ Ամբողջ տեղեկատվությունը ամբողջ երկրում կառուցվող բնակարանների մասին:',
    home_description: 'Ամբողջ տեղեկատվությունը Հայաստանում նորակառույցների մասին։ Մեր կայքում կգտնեք մեկնաբանություններ, նախագծեր, կառուցապատողներ և կառուցվող բնակարանների վերաբերյալ այլ մանրամասներ:',
    projects_title: 'Ստուգված նորակառույցների միասնական բազա Հայաստանում։',
    projects_description: 'Հայաստանի բոլոր նորակառույցները։ Քարտեզի վրա գտնվելու վայրը, լուսանկարները, կոնտակտները, յուրաքանչյուր օբյեկտի վերաբերյալ մանրամասն տեղեկատվություն։ Այս ամենը գտնվում է Հայաստանի նորակառույցների ստուգված տվյալների բազայում:',
    projects_with_location_title: (ctx) => {
        let title_text = ''
        let check = !ctx.named('city_name') && !ctx.named('developer_name') && !ctx.named('year')
        if (!check) {
            console.log(ctx.named('city_name'),ctx.named('developer_name'),ctx.named('year'))
            if (ctx.named('city_name') && ctx.named('year') === 'ready') {
                title_text += `Նորակառույցներ ${ctx.named('city_name')} քաղաքում`
            } else if (ctx.named('city_name')) {
                title_text += `${ctx.named('city_name')} քաղաքում նորակառույցներ`
            } else if (!ctx.named('city_name')) {
                title_text += `Նորակառույցներ`
            }

            if (ctx.named('developer_name')) {
                title_text += ` ${ctx.named('developer_name')} կառուցապատողից`
            } else if (!ctx.named('developer_name') && !ctx.named('year')) {
                title_text += ' կառուցապատողից'
            }

            if (ctx.named('year') === 'ready') {
                title_text += ' հանձնված:'
            } else if (ctx.named('year')) {
                title_text += `, հանձնումը՝ ${ctx.named('year')} թ․:`
            } else if (!ctx.named('year')) {
                title_text += ':'
            }
        } else {
            title_text = 'Նորակառույցներ կառուցապատողից:'
        }

        return title_text
    },
    projects_with_location_description: (ctx) => {
        let text = 'Գնեք բնակարան '
        let check = !ctx.named('city_name') && !ctx.named('developer_name') && !ctx.named('year')
        if (!check) {
            if (!ctx.named('city_name') && !ctx.named('year') && ctx.named('developer_name')) {
                text += `${ctx.named('developer_name')} կառուցապատողից:`
            } else if (ctx.named('developer_name')) {
                text += `${ctx.named('developer_name')} կառուցապատողից `
            }


            if (!ctx.named('year') && !ctx.named('developer_name') && ctx.named('city_name')) {
                text += `${ctx.named('city_name')} քաղաքում նորակառույց շենքում:`
            } else if (ctx.named('city_name')) {
                text += `${ctx.named('city_name')} քաղաքում`
            }

            if (ctx.named('year') === 'ready') {
                text += ' հանձնված նորակառույց շենքում:'
            } else if (ctx.named('year')) {
                text += ` նորակառույց շենքում, հանձնվող ${ctx.named('year')} թ․:`
            } else if (ctx.named('developer_name') && ctx.named('year')) {
                text += `${ctx.named('developer_name')} կառուցապատողից:`
            }

        } else {
            text = 'Գնեք բնակարան նորակառույց շենքում:'
        }
        return text
    },
    project_with_name_title: (ctx) => `ԲՀ ${ctx.named('project_name')} ֊ ամբողջ մանրամասն տեղեկատվությունը բնակելի համալիրի մասին։`,
    project_with_name_description: (ctx) => `ԲՀ ${ctx.named('project_name')}. Տեսնել գնորդների մեկնաբանությունները ${ctx.named('project_name')} նախագծի մասին։ Նկարներ, նկարագրություն, կառուցվածք, բանկերի առաջարկներ։`,
    banks_title: 'Հայաստանի բանկերը՝ հիփոթեքով նորակառույց շենքերում բնակարան ձեռք բերելու համար.',
    banks_description: 'Գնել հիփոթեքով բնակարան, Հայաստանի նորակառույց շենքերից մեկում բանկերից մեկի օգնությամբ։',
    bank_with_name_title: (ctx) => `${ctx.named('bank_name')} բանկը Հայաստանում - մեկնաբանություններ, հիփոթեքային ծրագրեր։`,
    bank_with_name_description: (ctx) => `${ctx.named('bank_name')} բանկի էջը կայքում՝ տեղեկատվություն, կոնտակտներ և հղում դեպի բանկի պաշտոնական կայք:`,
    developers_title: 'Հայաստանի Կառուցապատող ընկերությունները, կառուցապատողների բազա ամբողջ երկրից։',
    developers_description: 'Հայաստանում կառուցապատող ընկերությունների մանրամասն տվյալների բազա։ Կառուցապատողների կողմից տրամադրվող արդիական նոր շենքեր, մեկնաբանություններ և այլ տեղեկություններ:',
    developer_title: (ctx) => `${ctx.named('developer_name')} կառուցապատող ընկերությունը Հայաստանում։ Գնորդների մեկնաբանությունները և նորակառույց շենքերը։ `,
    developer_description: (ctx) => `${ctx.named('developer_name')} կառուցապատողի էջը մեր կայքում, կոնտակտային տվյալները, պաշտոնական կայքէջի հղումը,${ctx.named('developer_name')} կառուցապատող ընկերության մանրամասն նկարագրությունը և նոր շենքերի ցուցակը:`,
    blogs_title: 'Նորություններ և հոդվածներ Հայաստանում անշարժ գույքի մասին:',
    blogs_description: 'Տեղեկացնում ենք անշարժ գույքի շուկայում տեղի ունեցող հետաքրքիր իրադարձությունների մասին։ Ամեն ինչ՝ կառուցապատողների կողմից առաջխաղացումների, փորձագետների վերլուծության և ավելին: Շուկայի բոլոր նորությունները.',
    blog_title: (ctx) => `${ctx.named('blog_name')}`,
    blog_description: (ctx) => `<<${ctx.named('blog_name')}>> – նորություններ և հոդվածներ GetHome կայքում։ `,
    cities_title: 'Նորակառույցներ՝ Հայաստանի բոլոր քաղաքներում։',
    cities_description: 'Հայաստանի բոլոր քաղաքներում կառուցվող տների ապացուցված տվյալների բազա կառուցապատողից՝ նախագծեր, գնորդների կարծիքներ, քարտեզի վրա գտնվելու վայրը և շատ ավելին:',
    city_title: (ctx) => `Նորակառույցներ ${ctx.named('city_name')} քաղաքում։`,
    city_description: (ctx) => `Կառուցապատողների և բնակելի համալիրների ստուգված բազան ${ctx.named('city_name')} քաղաքում: Բոլոր տեղեկությունները ${ctx.named('city_name')} քաղաքում կառուցվող տների մասին։`,
    about_title: 'Gethome.am կայքի մասին։',
    about_description: 'Gethome.am-ը կայք է Հայաստանում նորակառույցների և բնակելի համալիրների մասին:'
}
