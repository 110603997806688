export default {
    popular_new_buildings: 'Հանրաճանաչ նոր շենքեր',
    discounts_and_special_offers_from_developers: 'Զեղչեր և հատուկ առաջարկներ մշակողների կողմից',
    developers: 'Կառուցապատողներ',
    developer: 'Կառուցապատող',
    projects: 'Նախագծեր',
    project: 'Նախագիծ',
    project1: 'Նախագծեր',
    project2: 'Նախագծեր',
    projects_on_map: 'Նախագծեր քարտեզի վրա',
    more: 'Ավելին',
    from: '-ից',
    infrastructure: 'Ենթակառուցվածք',
    similar_offers: 'Նմանատիպ առաջարկներ',
    how_to_get_to: 'Ինչպես հասնել',
    how_to_buy: 'Ինչպես կարելի է գնել',
    deadline: 'Վերջնաժամկետ',
    building_type: 'Շենքի տեսակը',
    number_of_storeys: 'Հարկերի քանակը',
    ceiling_height: 'Առաստաղի բարձրություն',
    parking: 'Ավտոկանգառ',
    new_building_class: 'Նորակառույց դաս',
    rating: 'Վարկանիշ',
    stage_of_construction: 'Շինարարության փուլ',
    detailed_information: 'Մանրամասն տեղեկություններ',
    apartment_type: 'Բնակարանի տեսակը',
    footage: 'Տեսանյութում',
    price_up_to: 'Գինը մինչև',
    status: 'Կարգավիճակ',
    legal_documents: 'Իրավաբանական փաստաթղթեր',
    specifications: 'Տեխնիկական պայմաններ',
    purchase_methods: 'Գնման մեթոդներ',
    offers_from_banks: 'Առաջարկներ բանկերից',
    mortgage_calculator: 'Հիփոթեքային հաշվիչ',
    calculate: 'հաշվարկել',
    apartment_price: 'Բնակարանի գինը',
    initial_fee: 'Նախնական վճար',
    mortgage_term: 'Հիփոթեքի ժամկետ',
    interest_rate: 'Տոկոսադրույքը',
    rub: '֏',
    years: 'տարիներ',
    interest_per_year: '% տարում',
    month_pay: 'Ամսական վճարում',
    over_costs: 'Գերավճարի գումարը',
    general_costs: 'Ընդհանուր ծախսեր',
    wrong_data: 'Մուտքագրվել են անվավեր տվյալներ',
    travel_time: 'Ճամփորդության ժամանակը.',
    review: 'Վերանայում',
    reviews: 'Կարծիքներ',
    leave_feedback: 'Թողնել կարծիք',
    main_filters: 'Ստուգված նոր շենքերի միասնական բազա',
    form_filter_city: 'քաղաք',
    form_filter_residential_complexes: 'Բնակելի համալիրներ',
    form_filter_deadline: 'Վերջնաժամկետ',
    form_filter_total_area_from: 'Ընդհանուր մակերեսը սկսած',
    form_filter_with_finishing: 'Հարդարման հետ',
    form_filter_btn_clear: 'Պարզ',
    form_filter_btn_apply: 'Դիմել',
    form_your_name: 'Քո անունը*',
    form_your_email: 'Քո էլէկտրոնային փոստը*',
    form_your_phone: 'Ձեր հեռախոսի համարը',
    form_your_surname: 'Ձեր ազգանունը*',
    form_evaluation: 'Դասարան',
    form_advantages: 'Առավելությունները',
    form_flaws: 'Թերություններ',
    comment_form_evaluation: 'Դասարան',
    comment_form_advantages: 'Առավելությունները',
    comment_form_flaws: 'Թերություններ',
    form_btn_send: 'ուղարկել',
    btn_fix_price: 'Գինը շտկել',
    btn_purchase_methods: 'Գնման մեթոդներ',
    btn_slide_img_every: 'Ամեն ինչ',
    btn_slide_img_general_plan: 'Ընդհանուր պլան',
    btn_slide_img_general_visibility: 'Ընդհանուր ձև',
    btn_slide_img_facade: 'Ճակատ',
    btn_slide_img_apartments_finishing: 'Հարդարման աշխատանքներով բնակարաններ',
    btn_slide_img_view_from_windows: 'Տեսարան պատուհաններից',
    btn_slide_img_beautification: 'գեղեցկացում',
    btn_slide_img_visualization: 'Վիզուալիզացիա',
    btn_appointment_for_viewing: 'Նշանակում դիտելու համար',
    btn_online_deal: 'Առցանց գործարք',
    btn_chat_with_consultant: 'Զրուցեք խորհրդատուի հետ',
    checkbox_on_foot: 'Ոտքով',
    checkbox_on_transport: 'Տրանսպորտի վրա',
    hour: 'ժ',
    minutes: 'մ',
    enter_work_address: 'Մուտքագրեք աշխատանքի հասցեն',
    build_route: 'Կառուցեք երթուղի',
    kindergartens: 'Մանկապարտեզներ',
    schools: 'Դպրոցներ',
    higher_education_institutions: 'Բարձրագույն ուսումնական հաստատություններ',
    medicine: 'Բժշկությունը',
    restaurants: 'Ռեստորաններ',
    sport: 'Սպորտ',
    shopping_centers: 'Առևտրի կենտրոններ',
    multifunctional_center: 'Բազմաֆունկցիոնալ կենտրոն',
    by_city: 'Քաղաք',
    by_cost: 'Ըստ արժեքի',
    by_class: 'Ըստ դասարանի',
    economy_class: 'Էկոնոմ դաս',
    comfort_class: 'Հարմարավետություն - դաս',
    business_class: 'Բիզնես կարգի',
    elite: 'Էլիտար',
    by_year_of_completion: 'Ըստ առաքման տարվա',
    rented: 'Վարձով է տրվում',
    for_rent_in_2021: 'Վարձով 2021թ',
    for_rent_in_2022: 'Վարձով 2022թ',
    for_rent_in_2023: 'Վարձով 2023թ',
    for_rent_in_2024: 'Վարձով 2024թ',
    show_more: 'Ցույց տալ ավելին',
    initial_payment: 'Նախնական վճարում',
    amount_of_credit: 'Վարկի չափը',
    overpayment: 'Գերավճար',
    program: 'Ծրագիր',
    bid: 'Հայտ',
    beginning_contribution: 'Սկիզբը ներդրում',
    credit_term: 'Վարկի ժամկետ',
    payment_per_month: 'Ամսական վճարում',
    by_completion_date: 'Ըստ ավարտի ամսաթվի',
    number_of_floors: 'Հարկերի քանակը',
    popular_queries: 'Հանրաճանաչ հարցումներ',
    rooms: ['Ստուդիա', '1-սենյակ', '2-սենյակ', '3-սենյակ', '4-սենյակ'],
    ready: 'Ավարտված'
}
