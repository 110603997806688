export default {
    blog: "Блог",
    comments: "Комментарии",
    username: "Ваше имя",
    comment_text: "Текст комментария",
    register: 'Зарегистрируйтесь',
    or: 'или',
    login: 'авторизируйтесь',
    send: "Отправить"
};
