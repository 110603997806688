export default {
    blog: "Blog",
    comments: "Comments",
    username: "Name",
    comment_text: "Text",
    register: 'Register',
    or: 'or',
    login: 'login',
    send: "Send"
};
