import languages from "./hy/languages";
import auth from "./hy/auth.js";
import menu from "./hy/menu.js";
import headers from "./hy/headers.js";
import entities from "./hy/entities";
import filterForm from './hy/filterForm';
import info_card from "./hy/info_card";
import projects_show from "@/locales/hy/projects_show";
import banks_filter from "@/locales/hy/banks_filter";
import cities_show from "@/locales/hy/cities_show";
import blog from "@/locales/hy/blog"
import meta from "@/locales/hy/meta";

export default {
    languages,
    auth,
    menu,
    headers,
    entities,
    filterForm,
    info_card,
    projects_show,
    banks_filter,
    cities_show,
    blog,
    meta
}
