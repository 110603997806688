export default {
    popular_new_buildings: 'Popular new buildings',
    discounts_and_special_offers_from_developers: 'Discounts and special offers from developers',
    developers: 'Developers',
    developer: 'Developer',
    projects: 'Projects',
    project: 'Project',
    project1: 'Projects',
    project2: 'Projects',
    projects_on_map: 'Projects on map',
    more: 'More',
    from: 'from',
    infrastructure: 'Infrastructure',
    similar_offers: 'Similar offers',
    how_to_get_to: 'How to get to',
    how_to_buy: 'How to buy',
    deadline: 'Deadline',
    building_type: 'Building type',
    number_of_storeys: 'Number of storeys',
    ceiling_height: 'Ceiling height',
    parking: 'Parking',
    new_building_class: 'New building class',
    rating: 'Rating',
    stage_of_construction: 'Stage of construction',
    detailed_information: 'Detailed information',
    apartment_type: 'Apartment type',
    footage: 'Footage',
    price_up_to: 'Price up to',
    status: 'Status',
    legal_documents: 'Legal documents',
    specifications: 'Specifications',
    purchase_methods: 'Purchase methods',
    offers_from_banks: 'Offers from banks',
    mortgage_calculator: 'Mortgage Calculator',
    calculate: 'Calculate',
    apartment_price: 'Apartment price',
    initial_fee: 'An initial fee',
    mortgage_term: 'Mortgage term',
    interest_rate: 'Interest rate',
    rub: '֏',
    years: 'years',
    interest_per_year: '% per year',
    month_pay: 'Monthly payment',
    over_costs: 'Overpayment amount',
    general_costs: 'General costs',
    wrong_data: 'Invalid data entered',
    travel_time: 'Travel time:',
    review: 'Review',
    reviews: 'Reviews',
    similar_apartments: 'Similar apartments',
    offers: 'Similar offers',
    leave_feedback: 'Leave feedback',
    main_filters: 'Unified database of verified new buildings',
    form_filter_city: 'City',
    form_filter_residential_complexes: 'Residential complexes',
    form_filter_deadline: 'Completion',
    form_filter_total_area_from: 'Total area from',
    form_filter_with_finishing: 'With finishing',
    form_filter_btn_clear: 'Clear',
    form_filter_btn_apply: 'Apply',
    form_your_name: 'Your name*',
    form_your_email: 'Your e-mail*',
    form_your_phone: 'Your phone number',
    form_your_surname: 'Your surname*',
    form_evaluation: 'Evaluation',
    form_advantages: 'Advantages',
    form_flaws: 'Flaws',
    comment_form_evaluation: 'Evaluation',
    comment_form_advantages: 'Advantages',
    comment_form_flaws: 'Advantages',
    form_btn_send: 'Send',
    btn_fix_price: 'Fix the price',
    btn_purchase_methods: 'Purchase methods',
    btn_slide_img_every: 'All',
    btn_slide_img_general_plan: 'General plan',
    btn_slide_img_general_visibility: 'General visibility',
    btn_slide_img_facade: 'Facade',
    btn_slide_img_apartments_finishing: 'Apartments with finishing',
    btn_slide_img_view_from_windows: 'View from windows',
    btn_slide_img_beautification: 'Beautification',
    btn_slide_img_visualization: 'Visualization',
    btn_appointment_for_viewing: 'Appointment for viewing',
    btn_online_deal: 'Online deal',
    btn_chat_with_consultant: 'Chat with a consultant',
    checkbox_on_foot: 'On foot',
    checkbox_on_transport: 'On car',
    hour: 'h',
    minutes: 'm',
    enter_work_address: 'Enter work address',
    build_route: 'Build a route',
    kindergartens: 'Kindergartens',
    schools: 'Schools',
    higher_education_institutions: 'Higher education institutions',
    medicine: 'The medicine',
    restaurants: 'Restaurants',
    sport: 'Sport',
    shopping_centers: 'Shopping centers',
    multifunctional_center: 'Multifunctional Center',
    by_city: 'By Location',
    by_cost: 'By cost',
    by_class: 'By class',
    economy_class: 'Economy class',
    comfort_class: 'Comfort - class',
    business_class: 'Business Class',
    elite: 'Elite',
    by_year_of_completion: 'By year of delivery',
    rented: 'Rented',
    for_rent_in_2021: 'For rent in 2021',
    for_rent_in_2022: 'For rent in 2022',
    for_rent_in_2023: 'For rent in 2023',
    for_rent_in_2024: 'For rent in 2024',
    show_more: 'Show more',
    initial_payment: 'Initial payment',
    amount_of_credit: 'Amount of credit',
    overpayment: 'Overpayment',
    program: 'Program',
    bid: 'Bid',
    beginning_contribution: 'Beginning contribution',
    credit_term: 'Credit term',
    payment_per_month: 'Payment per month',
    by_completion_date: 'By completion date',
    number_of_floors: 'Number of floors',
    popular_queries: 'Popular queries',
    rooms: ['Studio', '1-bedroom', '2-bedroom', '3-bedroom', '4-bedroom'],
    ready: 'Ready'
}
