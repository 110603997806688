export default {
    popular_new_buildings: 'Популярные новостройки',
    discounts_and_special_offers_from_developers: 'Скидки и спецпредложения от застройщиков',
    developers: 'Застройщики',
    developer: 'Застройщик',
    projects: 'Проекты',
    project: 'Проект',
    project1: 'Проекта',
    project2: 'Проектов',
    projects_on_map: 'Проекты на карте',
    more: 'Ещё',
    from: 'от',
    infrastructure: 'Инфраструктура',
    similar_offers: 'Похожие предложения',
    how_to_get_to: 'Как проехать',
    how_to_buy: 'Как купить',
    deadline: 'Срок сдачи',
    building_type: 'Тип здания',
    number_of_storeys: 'Этажность',
    ceiling_height: 'Высота потолка',
    parking: 'Парковка',
    new_building_class: 'Класс новостройки',
    rating: 'Рейтинг',
    stage_of_construction: 'Стадия строительства',
    detailed_information: 'Подробная информация',
    apartment_type: 'Тип квартиры',
    footage: 'Метраж, от',
    price_up_to: 'Цена до',
    status: 'Статус',
    legal_documents: 'Правовые документы',
    specifications: 'Характеристики',
    purchase_methods: 'Способы покупки',
    offers_from_banks: 'Предложения от банков',
    mortgage_calculator: 'Ипотечный калькулятор',
    calculate: 'Рассчитать',
    apartment_price: 'Стоимость квартиры',
    initial_fee: 'Первоначальный взнос',
    mortgage_term: 'Срок ипотеки',
    interest_rate: 'Процентная ставка',
    rub: '֏',
    years: 'лет',
    interest_per_year: '% в год',
    month_pay: 'Ежемесячный платеж',
    over_costs: 'Сумма переплаты',
    general_costs: 'Общие затраты',
    wrong_data: 'Введены неверные данные',
    travel_time: 'Время на дорогу:',
    review: 'Отзыв',
    reviews: 'Отзывы',
    similar_apartments: 'Похожие квартиры',
    offers: 'Похожие предложения',
    leave_feedback: 'Оставить отзыв',
    main_filters: 'Единая база проверенных новостроек',
    form_filter_city: 'Город',
    form_filter_residential_complexes: 'Жилые комплексы',
    form_filter_deadline: 'Срок сдачи',
    form_filter_total_area_from: 'Общая площадь от',
    form_filter_with_finishing: 'С отделкой',
    form_filter_btn_clear: 'Очистить',
    form_filter_btn_apply: 'Применить',
    form_your_name: 'Ваше имя*',
    form_your_email: 'Ваш e-mail*',
    form_your_phone: 'Ваш номер телефона',
    form_your_surname: 'Ваша фамилия*',
    form_evaluation: 'Оценка ЖК',
    form_advantages: 'Преимущества ЖК',
    form_flaws: 'Недостатки ЖК',
    comment_form_evaluation: 'Оценка банка',
    comment_form_advantages: 'Преимущества банка',
    comment_form_flaws: 'Недостатки банка',
    form_btn_send: 'Отправить',
    btn_fix_price: 'Зафиксировать цену',
    btn_purchase_methods: 'Способы покупки',
    btn_slide_img_every: 'Все',
    btn_slide_img_general_plan: 'Генеральный план',
    btn_slide_img_general_visibility: 'Общий вид',
    btn_slide_img_facade: 'Фасад',
    btn_slide_img_apartments_finishing: 'Квартиры с отделкой',
    btn_slide_img_view_from_windows: 'Вид из окон',
    btn_slide_img_beautification: 'Благоустройство',
    btn_slide_img_visualization: 'Визуализация',
    btn_appointment_for_viewing: 'Запись на просмотр',
    btn_online_deal: 'Онлайн сделка',
    btn_chat_with_consultant: 'Чат с консультантом',
    checkbox_on_foot: 'Пешком',
    checkbox_on_transport: 'На транспорте',
    hour: 'ч',
    minutes: 'м',
    enter_work_address: 'Введите адрес работы',
    build_route: 'Построить маршрут',
    kindergartens: 'Детские сады',
    schools: 'Школы',
    higher_education_institutions: 'Высшие учебные заведедния',
    medicine: 'Медицина',
    restaurants: 'Рестораны',
    sport: 'Спорт',
    shopping_centers: 'Торговые центры',
    multifunctional_center: 'МФЦ',
    by_city: 'По городу',
    by_cost: 'По стоимости',
    by_class: 'По классу',
    economy_class: 'Эконом - класс',
    comfort_class: 'Комфорт - класс',
    business_class: 'Бизнес - класс',
    elite: 'Элитные',
    by_year_of_completion: 'По году сдачи',
    rented: 'Сданные',
    for_rent_in_2021: 'Сдаются в 2021 году',
    for_rent_in_2022: 'Сдаются в 2022 году',
    for_rent_in_2023: 'Сдаются в 2023 году',
    for_rent_in_2024: 'Сдаются в 2024 году',
    show_more: 'Показать ещё',
    initial_payment: 'Начальный взнос',
    amount_of_credit: 'Сумма кредита',
    overpayment: 'Переплата',
    program: 'Программа',
    bid: 'Ставка',
    beginning_contribution: 'Нач. ставка',
    credit_term: 'Срок кредита',
    payment_per_month: 'Платёж в месяц',
    by_completion_date: 'По дате завершения',
    number_of_floors: 'Количество этажей',
    popular_queries: 'Популярные запросы',
    rooms: ['Студия', '1-комнатная', '2-комнатная', '3-комнатная', '4-комнатная'],
    ready: 'Готовые'
}
