import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import './plugins/vue-auth'
import './plugins/vue-gtm'
import './plugins/vuetify-google-autocomplete'
import 'axios-progress-bar/dist/nprogress.css'
import {loadProgressBar} from 'axios-progress-bar'
import i18n from './i18n'
import _ from 'lodash';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import VueMeta from 'vue-meta'

Object.defineProperty(Vue.prototype, '$_', { value: _ });

import './styles/variables.scss'

Vue.use(CKEditor);
Vue.use(VueMeta);
Vue.config.productionTip = false
require('./bootstrap');
loadProgressBar()

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
