<template>
    <front-layout>
        <filter-home-page @search="searchProjects" />

        <v-container fluid class="bg-f5f5f5 py-1">
            <v-container>
                <v-row>
                    <v-col md="9" class="pa-0">
                        <v-row class="text-center">
                            <v-col class="pt-0">
                                <open-street-map-multi-marker v-if="projects.length" :lat="40.183565" :lng="44.514343"
                                    zoom="14" id="main-map" :markers="projects"
                                    :height="$vuetify.breakpoint.width < 1240 ? 200 : 500" />
                                <v-skeleton-loader v-else type="image@3"
                                    :height="$vuetify.breakpoint.width < 1240 ? 200 : 500" tile></v-skeleton-loader>
                            </v-col>
                        </v-row>

                        <v-row v-if="$vuetify.breakpoint.width < 900">
                            <v-col>
                                <h2 class="text-h5 font-weight-bold">Сервисы</h2>
                            </v-col>
                        </v-row>

                        <v-row v-if="$vuetify.breakpoint.width < 900" class="mb-2 px-0 mx-0">
                            <v-col xs="" sm="3" md="3" lg="3" xl="3" cols="3" class="pb-0">
                                <v-card
                                    class="transparent mx-auto text-center d-flex flex-column align-center justify-center"
                                    elevation="0">
                                    <a href="#" class="d-block text-decoration-none main_color_text">
                                        <div class="wrap_icon bg_white d-flex justify-center align-center mx-auto">
                                            <v-icon>mdi-percent-outline</v-icon>
                                        </div>
                                        <v-card-title class="text-center word-break fz-13 line-height16">Ипотека
                                        </v-card-title>
                                    </a>
                                </v-card>
                            </v-col>
                            <v-col xs="" sm="3" md="3" lg="3" xl="3" cols="3" class="pb-0">
                                <v-card
                                    class="transparent mx-auto text-center d-flex flex-column align-center justify-center"
                                    elevation="0">
                                    <a href="#" class="d-block text-decoration-none main_color_text">
                                        <div class="wrap_icon bg_white d-flex justify-center align-center mx-auto">
                                            <v-icon>mdi-currency-rub</v-icon>
                                        </div>
                                        <v-card-title class="text-center word-break fz-13 line-height16">Оценка
                                        </v-card-title>
                                    </a>
                                </v-card>
                            </v-col>
                            <v-col xs="" sm="3" md="3" lg="3" xl="3" cols="3" class="pb-0">
                                <v-card
                                    class="transparent mx-auto text-center d-flex flex-column align-center justify-center"
                                    elevation="0">
                                    <a href="#" class="d-block text-decoration-none main_color_text">
                                        <div class="wrap_icon bg_white d-flex justify-center align-center mx-auto">
                                            <v-icon>mdi-account-outline</v-icon>
                                        </div>
                                        <v-card-title class="text-center word-break fz-13 line-height16">Подбор
                                            риелтора
                                        </v-card-title>
                                    </a>
                                </v-card>
                            </v-col>
                            <v-col xs="" sm="3" md="3" lg="3" xl="3" cols="3" class="pb-0">
                                <v-card
                                    class="transparent mx-auto text-center d-flex flex-column align-center justify-center"
                                    elevation="0">
                                    <a href="#" class="d-block text-decoration-none main_color_text">
                                        <div class="wrap_icon bg_white d-flex justify-center align-center mx-auto">
                                            <v-icon>mdi-key-chain-variant</v-icon>
                                        </div>
                                        <v-card-title class="text-center white-space fz-13 line-height16">Сдам/сниму
                                        </v-card-title>
                                    </a>
                                </v-card>
                            </v-col>
                            <v-col xs="" sm="3" md="3" lg="3" xl="3" cols="3" class="pb-0">
                                <v-card
                                    class="transparent mx-auto text-center d-flex flex-column align-center justify-center"
                                    elevation="0">
                                    <a href="#" class="d-block text-decoration-none main_color_text">
                                        <div class="wrap_icon bg_white d-flex justify-center align-center mx-auto">
                                            <v-icon>mdi-calculator-variant-outline</v-icon>
                                        </div>
                                        <v-card-title class="text-center word-break fz-13 line-height16">Ипотечный
                                            калькулятор
                                        </v-card-title>
                                    </a>
                                </v-card>
                            </v-col>
                            <v-col xs="" sm="3" md="3" lg="3" xl="3" cols="3" class="pb-0">
                                <v-card
                                    class="transparent mx-auto text-center d-flex flex-column align-center justify-center"
                                    elevation="0">
                                    <a href="#" class="d-block text-decoration-none main_color_text">
                                        <div class="wrap_icon bg_white d-flex justify-center align-center mx-auto">
                                            <v-icon>mdi-wallet-plus-outline</v-icon>
                                        </div>
                                        <v-card-title class="text-center word-break fz-13 line-height16">Копить или
                                            купить
                                        </v-card-title>
                                    </a>
                                </v-card>
                            </v-col>
                            <v-col xs="" sm="3" md="3" lg="3" xl="3" cols="3" class="pb-0">
                                <v-card
                                    class="transparent mx-auto text-center d-flex flex-column align-center justify-center"
                                    elevation="0">
                                    <a href="#" class="d-block text-decoration-none main_color_text">
                                        <div class="wrap_icon bg_white d-flex justify-center align-center mx-auto">
                                            <v-icon>mdi-text-box-check-outline</v-icon>
                                        </div>
                                        <v-card-title class="text-center word-break fz-13 line-height16">Проверка
                                            анкеты
                                        </v-card-title>
                                    </a>
                                </v-card>
                            </v-col>
                            <v-col xs="" sm="3" md="3" lg="3" xl="3" cols="3" class="pb-0">
                                <v-card
                                    class="transparent mx-auto text-center d-flex flex-column align-center justify-center"
                                    elevation="0">
                                    <a href="#" class="d-block text-decoration-none main_color_text">
                                        <div class="wrap_icon bg_white d-flex justify-center align-center mx-auto">
                                            <v-icon>mdi-finance</v-icon>
                                        </div>
                                        <v-card-title class="text-center word-break fz-13 line-height16">Пульс рынка
                                        </v-card-title>
                                    </a>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row class="mt-0">
                            <v-col cols="12" sm="12" md="6" lg="6" xl="6" v-for="item in data_info_block"
                                :key="item.id">
                                <info-block-home-page :data_info_block="item" />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <h2 class="text-h5 font-weight-bold">{{ $t('headers.popular_new_buildings') }}</h2>
                            </v-col>
                        </v-row>
                        <loading-grid v-if="isLoading" />
                        <v-row v-else>
                            <v-col cols="12" sm="12" md="6" lg="4" xl="4" v-if="$vuetify.breakpoint.width < 900">
                                <v-carousel :show-arrows="false" height="370" hide-delimiter-background
                                    :continuous="false" reverse-transition="fade-transition"
                                    transition="fade-transition" class="carousel-circle align-content-stretch d-flex">
                                    <v-carousel-item v-for="item in popular_new_buildings"
                                        :key="`popular_new_buildings`+ item[0].id" :active-class="'active---class'">
                                        <popular-new-building :popular_new_buildings="item[0]" />
                                        <popular-new-building :popular_new_buildings="item[1]" />
                                    </v-carousel-item>
                                </v-carousel>
                            </v-col>
                            <v-col v-else cols="12" sm="12" md="6" lg="4" xl="4" class="align-content-stretch d-flex"
                                v-for="item in arr_buildings_desktop.slice(0, 6)" :key="'popular_new_build' + item.id">
                                <popular-new-building :popular_new_buildings="item" class="d-flex flex-column" />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <h2 class="text-h5 font-weight-bold">
                                    {{ $t('headers.discounts_and_special_offers_from_developers') }}</h2>
                            </v-col>
                        </v-row>
                        <v-row v-if="!isLoading">
                            <v-col cols="12" sm="12" md="6" lg="4" xl="4" v-if="$vuetify.breakpoint.width < 900">
                                <v-carousel :show-arrows="false" height="480" hide-delimiter-background
                                    :continuous="false" reverse-transition="fade-transition"
                                    transition="fade-transition" class="carousel-circle align-content-stretch d-flex">
                                    <v-carousel-item v-for="item in data_sale_card" :key="`data_sale_card`+ item[0].id"
                                        :active-class="'active---class'">
                                        <sale-card :data_sale_card="item[0]" class="mb-15" />
                                        <sale-card :data_sale_card="item[1]" class="mb-15" />
                                    </v-carousel-item>
                                </v-carousel>
                            </v-col>
                            <v-col v-else cols="12" sm="12" md="6" lg="4" xl="4"
                                v-for="item in arr_sale_card_desktop.slice(0, 6)" :key="'sale_card' + item.id">
                                <sale-card :data_sale_card="item" />
                            </v-col>
                        </v-row>
                        <v-row v-else>
                            <v-col cols="12" sm="12" md="6" lg="4" xl="4" v-if="$vuetify.breakpoint.width < 900">
                                <v-carousel :show-arrows="false" height="480" hide-delimiter-background
                                    :continuous="false" reverse-transition="fade-transition"
                                    transition="fade-transition" class="carousel-circle align-content-stretch d-flex">
                                    <v-carousel-item v-for="n in 6" :key="n" :active-class="'active---class'">
                                        <v-card v-for="i in 2" :key="i" elevation="0" class="mr-4 mr-md-0 bg-bg mb-15"
                                            height="420px" color="white">
                                            <div
                                                class="text-decoration-none d-flex flex-column link_popularNewBuildings">
                                                <v-col>
                                                    <v-skeleton-loader class="mb-6" type="image@2" width="300px"
                                                        height="250px"></v-skeleton-loader>
                                                </v-col>
                                            </div>
                                        </v-card>
                                    </v-carousel-item>
                                </v-carousel>
                            </v-col>
                            <v-col v-else cols="12" sm="12" md="6" lg="4" xl="4" v-for="n in 6" :key="n">
                                <v-skeleton-loader type="image@3" height="420"></v-skeleton-loader>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <h2 class="text-h5 font-weight-bold">{{ $t('headers.developers') }}</h2>
                            </v-col>
                        </v-row>
                        <v-row v-if="!isLoading" class="text-center mb-auto">
                            <v-col cols="12" sm="12" md="6" lg="4" xl="4" v-if="$vuetify.breakpoint.width < 900">
                                <v-carousel :show-arrows="false" height="450" hide-delimiter-background
                                    :continuous="false" reverse-transition="fade-transition"
                                    transition="fade-transition" class="carousel-circle align-content-stretch d-flex">
                                    <v-carousel-item v-for="developer in developers" :key="`developer`+ developer[0].id"
                                        :active-class="'active---class'">
                                        <developer-card :developer="developer[0]" />
                                        <developer-card :developer="developer[1]" />
                                    </v-carousel-item>
                                </v-carousel>
                            </v-col>
                            <v-col v-else cols="12" sm="12" md="6" lg="4" xl="4" class="align-content-stretch"
                                v-for="item in arr_developer_card_desktop.slice(0, this.countDevelopers)"
                                :key="'developer_card' + item.id">
                                <developer-card :developer="item" class="d-flex flex-column" />
                            </v-col>
                        </v-row>
                        <v-row v-else class="mb-auto">
                            <v-col cols="12" sm="12" md="6" lg="4" xl="4" v-if="$vuetify.breakpoint.width < 900">
                                <v-carousel :show-arrows="false" height="450" hide-delimiter-background
                                    :continuous="false" reverse-transition="fade-transition"
                                    transition="fade-transition" class="carousel-circle align-content-stretch d-flex">
                                    <v-carousel-item v-for="n in 5" :key="n" :active-class="'active---class'">
                                        <v-card v-for="i in 2" :key="i" elevation="0"
                                            class="mr-4 mr-md-0 bg-bg align-self-start" height="394.5px" color="white">
                                            <div
                                                class="text-decoration-none d-flex flex-column link_popularNewBuildings">
                                                <v-col>
                                                    <v-skeleton-loader class="mb-6" type="image@2" width="300px"
                                                        height="394.5px"></v-skeleton-loader>
                                                </v-col>
                                            </div>
                                        </v-card>
                                    </v-carousel-item>
                                </v-carousel>
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="4" xl="4" class="align-content-stretch" v-else
                                v-for="n in 3" :key="n">
                                <v-skeleton-loader type="image@3" height="395"></v-skeleton-loader>
                            </v-col>
                        </v-row>
                        <v-btn v-if="countDevelopers < arr_developer_card_desktop.length" @click="showMoreDevelopers"
                            class="main_color_text bg_white btn-contact_us button-wrapper my-3">{{ $t('headers.more') }}
                        </v-btn>
                        <v-row v-else-if="isLoading" class="mt-0">
                            <v-col class="d-flex justify-center">
                                <v-skeleton-loader type="image" width="66.4" height="36"></v-skeleton-loader>
                            </v-col>
                        </v-row>
                        <v-row class="text-center d-none">
                            <v-col cols="12" sm="12" md="6" lg="4" xl="4" v-for="project in projects"
                                :key="`project-`+project.id">
                                <project-card :project="project" />
                            </v-col>
                        </v-row>
                    </v-col>

                    <v-col md="3" class="pt-0 pr-0 pl-0 pl-md-4">
                        <div class="mt-6 mt-md-0">
                            <aside-link-variable-card />
                        </div>

                        <advertising :limit="3" />
                    </v-col>
                </v-row>
            </v-container>
        </v-container>
    </front-layout>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    components: {
        OpenStreetMapMultiMarker: () => import('@/components/OpenStreetMapMultiMarker'),
        FrontLayout: () => import('@/layouts/FrontLayout'),
        LoadingGrid: () => import('@/components/LoadingGrid'),
        FilterHomePage: () => import('@/components/filter/FilterHomePage'),
        InfoBlockHomePage: () => import('@/components/cards/InfoBlockCard'),
        PopularNewBuilding: () => import('@/components/cards/PopularNewBuildingCard'),
        SaleCard: () => import('@/components/cards/SaleCard'),
        ProjectCard: () => import('@/components/cards/ProjectCard'),
        DeveloperCard: () => import('@/components/cards/DeveloperCard'),
        AsideLinkVariableCard: () => import('@/components/cards/AsideLinkVariableCard'),
        Advertising: () => import('@/components/ads/Advertising'),
    },
    metaInfo() {
        return {
            title: this.$t('meta.home_title'),
            meta: [
                {
                    name: 'description',
                    content: this.$t('meta.home_description')
                }
            ]
        }
    },
    data() {
        return {
            projects_sort_apartments: [],
            popular_new_buildings: [],
            data_sale_card: [],
            developers_count: '',
            projects_count: '',
            data_info_block: [
                {
                    id: 1,
                    description: `${this.$t('info_card.description')}`,
                    linkTitle: `${this.$t('info_card.linkTitle')}`,
                    title: '',
                    linkHeader: {name: 'projects.index'},
                    linkFooter: {name: 'projects.index', query: {location: 'yerevan'}}
                },
                {
                    id: 2,
                    description: `${this.$t('info_card.description2')}`,
                    linkTitle: `${this.$t('info_card.linkTitle2')}`,
                    title: '',
                    linkHeader: {name: 'developers.index'},
                    linkFooter: {name: 'projects.index', query: {completion: 'ready'}}
                },
            ],
            arr_buildings_desktop: [],
            arr_sale_card_desktop: [],
            arr_developer_card_desktop: [],
            items: ['Foo', 'Bar', 'Fizz', 'Buzz', 'Fooz', 'Barz'],
            countDevelopers: 3
        }
    },

    computed: {
        ...mapGetters({
            isLoading: 'project/loading',
            projects: 'project/projects',
            developers: 'project/developers',
        })
    },

    mounted() {
        this.init()
    },

    methods: {
        async init() {
            await this.$store.dispatch('project/fetchDevelopers')
            await this.$store.dispatch('project/fetchProjects')

            this.data_info_block[0].title = this.$tc('entities.project', this.projects.length)
            this.data_info_block[1].title = this.$tc('entities.developer', this.developers.length)
            // await window.axios.get('api/projects/count').then(response => {
            //     this.$set(this.data_info_block[0],'title', `${this.$tc('entities.project', response.data)}`)
            // });
            this.arr_developer_card_desktop = this.developers
            let arr_developers = this.developers.slice(0, 10)

            this.$store.dispatch('project/setDevelopers', this.chunkArray(arr_developers, 2))

            this.arr_buildings_desktop = this.projects
            let arr_buildings = this.projects.slice(0, 10)
            this.popular_new_buildings = this.chunkArray(arr_buildings, 2)
            this.arr_sale_card_desktop = this.projects

            let arr_sale_card = this.projects.slice(0, 10)
            this.data_sale_card = this.chunkArray(arr_sale_card, 2)
        },
        searchProjects() {
            this.$router.push({ name: 'projects.index' })
        },
        chunkArray(arr, qty) {
            return arr.reduce((prev, cur, i, a) => !(i % qty) ? prev.concat([a.slice(i, i + qty)]) : prev, []);
        },
        showMoreDevelopers() {
            this.countDevelopers += 3
        }
    },

    beforeDestroy() {
        this.$store.dispatch('project/resetDevelopers')
        this.$store.dispatch('project/resetProjects')
    }
}
</script>

<style lang="scss" scoped>
@media all and (max-width: 900px) {

    .v-window-item--active,
    .active---class {
        max-width: 300px;
        min-height: 390px;
        overflow: visible;

        .v-carousel__item {
            overflow: visible !important;

            .v-responsive {
                display: flex !important;
                justify-content: space-between !important;
                align-items: center !important;
            }

            .v-responsive__content {
                display: flex;
                justify-content: space-between;
                align-items: center;

                &>a.text-decoration-none {
                    margin-right: 10px;
                }
            }
        }
    }
}

.bg_white {
    background: white;
}

.wrap_icon {
    width: 64px;
    height: 64px;
    border: 1px solid var(--main_color-base);
    border-radius: 0 !important;
}

.service_card {}

.word-break {
    word-break: keep-all;
}

.white-space {
    white-space: nowrap;
}

.fz-13 {
    font-size: 13px;
}

.line-height16 {
    line-height: 16px;
}

.banner_for_result_search {
    background: url("../assets/img/banner_for_result_search.jpg") center center/cover no-repeat no-repeat;
    min-width: 100%;
    height: 314px;
    display: inline-block;
    overflow: hidden;
    position: relative;

    &__header {
        font-style: normal;
        font-weight: 500;
        font-size: 48px;
        line-height: 59px;
        color: #fff;
        margin-left: 103px;
        margin-top: 70px;

        @media all and (max-width: 900px) {
            font-size: 36px;
            line-height: 44px;
            margin-left: 8px;
            margin-top: 24px;
        }
    }

    &__subtitle {
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 29px;
        color: #fff;
        max-width: 375px;
        margin-left: 103px;

        @media all and (max-width: 900px) {
            font-size: 18px;
            line-height: 22px;
            margin-left: 8px;
            max-width: 300px;
        }
    }

    &__img {
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 29px;
        color: #fff;
        max-width: 375px;
        margin-left: 103px;
        position: absolute;
        bottom: 0;
        right: 8px;
    }
}

.search_result__h3 {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: var(--main_color-base);
    margin-top: 10px;
    margin-bottom: 25px;
}

.similar-offers-card-home-page {
    @media all and (max-width: 900px) {
        min-width: 100% !important;

        .bg_white.min-width100 {
            max-height: 515px !important;
        }

        .v-image__image--cover {
            max-height: 200px !important;
        }

        .card-img-plan {
            max-height: 200px !important;
        }
    }
}

.max-height-560 {
    @media all and (max-width: 900px) {
        max-height: 560px;
        overflow: hidden;
    }
}

.button-wrapper {
    margin-top: 10px;
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);
}
</style>
