export default {
    description: 'Актуальная база жилых комплексов Армении с ежедневным обновлением',
    linkTitle: 'Новостройки рядом с Ереваном',

    description2: 'Единый справочник застройщиков и продавцов содержит отзывы покупателей квартир, информацию о банках-партнерах',
    linkTitle2: 'Компании с готовыми квартирами',
}



