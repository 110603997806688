export default {
    login: 'Войти',
    login1: 'Войдите,',
    login_title: 'чтобы добавить объект в избранное',
    email: 'E-mail',
    password: 'Пароль',
    phone_number: 'Номер телефона',
    forgot_password: 'Забыли пароль?',
    sign_up: 'Зарегистрироваться',
    sign_up1: 'Зарегистрируйтесь,',
    password_reset: 'Сброс пароля',
    reset: 'Сбросить',
    password_confirmation: 'Подтверждение пароля',
    agree: 'Я согласен на обработку персональных данных',
    enter: 'Войти'
}
