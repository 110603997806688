export default {
    login: 'Ներս մտնել',
    login1: 'Մուտք գործեք՝',
    login_title: 'ձեր ընտրյալներին որևէ տարր ավելացնելու համար:',
    email: 'Էլ',
    password: 'Գաղտնաբառ',
    phone_number: 'հեռախոսահամար',
    forgot_password: 'Մոռացել եք գաղտնաբառը?',
    sign_up: 'Գրանցվել',
    sign_up1: 'Գրանցվեք՝',
    password_reset: 'Գաղտնաբառի վերականգնում',
    reset: 'Վերականգնել',
    password_confirmation: 'Գաղտնաբառի հաստատում',
    agree: 'Ես համաձայն եմ անձնական տվյալների մշակմանը',
    enter: 'Մուտքագրեք'

}
