
export default [
    {
        path: '/admin/dashboard',
        name: 'admin.index',
        component: () => import('../views/admin/Index.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/profile',
        name: 'admin.profile',
        component: () => import('../views/admin/Profile.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/price-plans',
        name: 'admin.price-plans.index',
        component: () => import('../views/admin/price-plans/Index.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/price-plans/create',
        name: 'admin.price-plans.create',
        component: () => import('../views/admin/price-plans/Form.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/price-plans/:id',
        name: 'admin.price-plans.edit',
        component: () => import('../views/admin/price-plans/Form.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/users',
        name: 'admin.users.index',
        component: () => import('../views/admin/users/Index.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/users/:id',
        name: 'admin.users.show',
        component: () => import('../views/admin/users/Show.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/projects',
        name: 'admin.projects.index',
        component: () => import('../views/admin/projects/Index.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/projects/create',
        name: 'admin.projects.create',
        component: () => import('../views/admin/projects/Show.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/projects/:id',
        name: 'admin.projects.show',
        component: () => import('../views/admin/projects/Show.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/apartments',
        name: 'admin.apartments.index',
        component: () => import('../views/admin/apartments/Index.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/apartments/create',
        name: 'admin.apartments.create',
        component: () => import('../views/admin/apartments/Show.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/apartments/:id',
        name: 'admin.apartments.show',
        component: () => import('../views/admin/apartments/Show.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/developers',
        name: 'admin.developers.index',
        component: () => import('../views/admin/developers/Index.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/developers/create',
        name: 'admin.developers.create',
        component: () => import('../views/admin/developers/Show.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/developers/:id',
        name: 'admin.developers.show',
        component: () => import('../views/admin/developers/Show.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/banks',
        name: 'admin.banks.index',
        component: () => import('../views/admin/banks/Index.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/banks/:id',
        name: 'admin.banks.show',
        component: () => import('../views/admin/banks/Show.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/banks/create',
        name: 'admin.banks.create',
        component: () => import('../views/admin/banks/Show.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/cities',
        name: 'admin.cities.index',
        component: () => import('../views/admin/cities/Index.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/cities/:id',
        name: 'admin.cities.show',
        component: () => import('../views/admin/cities/Show.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/cities/create',
        name: 'admin.cities.create',
        component: () => import('../views/admin/cities/Show.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/leads',
        name: 'admin.leads.index',
        component: () => import('../views/admin/leads/Index.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/leads/:id',
        name: 'admin.leads.show',
        component: () => import('../views/admin/leads/Show.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/mortgages',
        name: 'admin.mortgages.index',
        component: () => import('../views/admin/mortgages/Index.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/mortgages/:id',
        name: 'admin.mortgages.show',
        component: () => import('../views/admin/mortgages/Show.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/mortgages/create',
        name: 'admin.mortgages.create',
        component: () => import('../views/admin/mortgages/Show.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/ads',
        name: 'admin.ads.index',
        component: () => import('../views/admin/ads/Index.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/ads/:id',
        name: 'admin.ads.show',
        component: () => import('../views/admin/ads/Show.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/ads/create',
        name: 'admin.ads.create',
        component: () => import('../views/admin/ads/Show.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/articles',
        name: 'admin.articles.index',
        component: () => import('../views/admin/articles/Index.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/articles/:id',
        name: 'admin.articles.show',
        component: () => import('../views/admin/articles/Show.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/articles/create',
        name: 'admin.articles.create',
        component: () => import('../views/admin/articles/Show.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/pages',
        name: 'admin.pages.index',
        component: () => import('../views/admin/pages/Index.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/pages/:id',
        name: 'admin.pages.show',
        component: () => import('../views/admin/pages/Show.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/pages/create',
        name: 'admin.pages.create',
        component: () => import('../views/admin/pages/Show.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/comments',
        name: 'admin.comments.index',
        component: () => import('../views/admin/comments/Index.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/comments/:id',
        name: 'admin.comments.show',
        component: () => import('../views/admin/comments/Show.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/tags',
        name: 'admin.tags.index',
        component: () => import('../views/admin/tags/Index.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/tags/:id',
        name: 'admin.tags.show',
        component: () => import('../views/admin/tags/Show.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/tags/create',
        name: 'admin.tags.create',
        component: () => import('../views/admin/tags/Show.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/reviews',
        name: 'admin.reviews.index',
        component: () => import('../views/admin/reviews/Index.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/reviews/:id',
        name: 'admin.reviews.show',
        component: () => import('../views/admin/reviews/Show.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/documents',
        name: 'admin.documents.index',
        component: () => import('../views/admin/documents/Index.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/documents/create',
        name: 'admin.documents.create',
        component: () => import('../views/admin/documents/Show.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/documents/:id',
        name: 'admin.documents.show',
        component: () => import('../views/admin/documents/Show.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/blogs',
        name: 'admin.blogs.index',
        component: () => import('../views/admin/blogs/Index.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/blogs/:id',
        name: 'admin.blogs.show',
        component: () => import('../views/admin/blogs/Show.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/blogs/create',
        name: 'admin.blogs.create',
        component: () => import('../views/admin/blogs/Show.vue'),
        meta: {
            auth: 'admin'
        }
    },
];
