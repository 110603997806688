import languages from "./ru/languages";
import auth from "./ru/auth.js";
import menu from "./ru/menu.js";
import headers from "./ru/headers.js";
import entities from "./ru/entities";
import filterForm from './ru/filterForm';
import info_card from "./ru/info_card";
import projects_show from "@/locales/ru/projects_show";
import banks_filter from "@/locales/ru/banks_filter";
import cities_show from "@/locales/ru/cities_show";
import blog from "@/locales/ru/blog"
import meta from "@/locales/ru/meta";

export default {
    languages,
    auth,
    menu,
    headers,
    entities,
    filterForm,
    info_card,
    projects_show,
    banks_filter,
    cities_show,
    blog,
    meta
}
