export default {
    login: 'Login',
    login1: 'Sign in',
    login_title: 'to add an object to your favourites',
    email: 'Email',
    password: 'Password',
    phone_number: 'Phone number',
    forgot_password: 'Forgot Password?',
    sign_up: 'Sign Up',
    sign_up1: 'Register',
    password_reset: 'Password Reset',
    reset: 'Reset',
    password_confirmation: 'Password Confirmation',
    agree: 'I agree to the processing of personal data',
    enter: 'Enter'

}
